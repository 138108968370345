// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-m-style-tsx": () => import("./../../../src/pages/about/m-style.tsx" /* webpackChunkName: "component---src-pages-about-m-style-tsx" */),
  "component---src-pages-about-ms-design-tsx": () => import("./../../../src/pages/about/ms-design.tsx" /* webpackChunkName: "component---src-pages-about-ms-design-tsx" */),
  "component---src-pages-about-ms-realty-tsx": () => import("./../../../src/pages/about/ms-realty.tsx" /* webpackChunkName: "component---src-pages-about-ms-realty-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-pages-recruit-form-tsx": () => import("./../../../src/pages/recruit_form.tsx" /* webpackChunkName: "component---src-pages-recruit-form-tsx" */),
  "component---src-pages-recruit-tsx": () => import("./../../../src/pages/recruit.tsx" /* webpackChunkName: "component---src-pages-recruit-tsx" */)
}

